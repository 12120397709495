import React, { useState, useEffect } from "react";
import { Table, Card, Space, Upload } from "lib";
import {
  Button,
  TablePaginationConfig,
  message,
  Form,
  Input,
  Modal,
} from "antd";
// import Qr from "@components/qr";
import { Layout } from "layout";
import {
  ClientGroupsClient,
  ClientGroupDto,
  ClientGroupAssessmentTemplatesClient,
  ClientGroupReportGenerationSettingsClient,
} from "@api";
import { showError, Download } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import ClientGroupMenu from "@components/Admin/ClientGroup/ClientGroupMenu";
import { RcFile } from "antd/lib/upload";
import { PlusOutlined } from "@ant-design/icons";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import TreeTable from "@components/Admin/ClientGroup/TreeTable";
import ClientGroupCreateModal from "@components/Admin/ClientGroup/ClientGroupCreateModal";

const defaultOrderBy = "name";
const defaultSortOrder = "ascend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(SECTION.Admin, "ClientGroups", "ClientGroups");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const clientGroupsClient = new ClientGroupsClient();
  const clientGroupAssessmentTemplatesClient =
    new ClientGroupAssessmentTemplatesClient();
  const clientGroupReportGenerationSettingsClient =
    new ClientGroupReportGenerationSettingsClient();

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [createSubGroupModalVisible, setCreateSubGroupModalVisible] =
    useState(false);
  const [createSubGroupModalGroup, setCreateSubGroupModalGroup] = useState(
    {} as ClientGroupDto
  );

  const [state, setState] = useState({
    items: [] as ClientGroupDto[],
    pagination: defaultPagination,
    loading: false,
  });

  const [expandedRowKeys, setExpandedRowKeys] = useState(
    undefined as React.Key[] | undefined
  );

  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "ClientGroups")) return;

    setState({
      ...state,
      loading: true,
    });
    const res = await clientGroupsClient.getRootClientGroupsWithPagination(
      _keywords ?? keywords,
      undefined,
      params.pagination.current,
      params.pagination.pageSize,
      capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
      (params.sortOrder ?? defaultSortOrder) == "ascend"
    );

    const items = res.items ?? [];

    setState({
      ...state,
      items,
      pagination: {
        ...params.pagination,
        total: res.totalCount,
      },
      loading: false,
    });
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleDownloadTemplates() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await clientGroupAssessmentTemplatesClient.export();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadTemplates(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await clientGroupAssessmentTemplatesClient.upload({
        data: f,
        fileName: f.name,
      });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleDownloadClientGroups() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await clientGroupsClient.export();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadClientGroups(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await clientGroupsClient.upload({ data: f, fileName: f.name });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleDownloadReportGenerationSetting() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await clientGroupReportGenerationSettingsClient.export();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadReportGenerationSetting(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await clientGroupReportGenerationSettingsClient.upload({
        data: f,
        fileName: f.name,
      });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleAddClientGroup(data: any) {
    try {
      if(data.maxAccount === String("") ){
        data.maxAccount = null;
      }
      if(data.maxAccountForSub  === String("") ){
        data.maxAccountForSub = null;
      }
      const hide = messageApi.loading(
        <FormattedMessage id="StaticCreatingMessage" />
      );
      try {
        await clientGroupsClient.createTop(data);
        messageApi.success(
          <FormattedMessage id="StaticCreateSuccessMessage" />
        );
        setCreateModalVisible(false);

        await fetchItems();
      } catch (err) {
        showError(err);
      } finally {
        hide();
      }
    } catch (err) {
      /* form error block*/
    }
  }

  function handleCancelAddClientGroup() {
    setCreateModalVisible(false);
  }

  async function OpenModalAddSubClientGroup(record: ClientGroupDto) {
    setCreateSubGroupModalGroup(record);
    setCreateSubGroupModalVisible(true);
  }

  async function handleArchiveClientGroup(id: number) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmArchiveClientGroup" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticUpdatingMessage" />
        );
        try {
          await clientGroupsClient.archiveClientGroup(id);
          messageApi.success(
            <FormattedMessage id="StaticUpdateSuccessMessage" />
          );
          await fetchItems();

          setExpandedRowKeys([]); // collapse all expended
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }
  const handleAddSubClientGroup = async (data: any) => {
    try {
      if(data.maxAccount === String("") ){
        data.maxAccount = null;
      }
      if(data.maxAccountForSub  === String("") ){
        data.maxAccountForSub = null;
      }
      const hide = messageApi.loading(
        <FormattedMessage id="StaticCreatingMessage" />
      );
      const value = { ...data, parentGroupId: createSubGroupModalGroup.id };
      try {
        await clientGroupsClient.create(value);
        messageApi.success(
          <FormattedMessage id="StaticCreateSuccessMessage" />
        );
        setCreateSubGroupModalVisible(false);

        await fetchItems();

        setExpandedRowKeys([]); // collapse all expended
      } catch (err) {
        showError(err);
      } finally {
        hide();
      }
    } catch (err) {
      /* form error block*/
    }
  };

  const handleCancelAddSubClientGroup = () => {
    setCreateSubGroupModalVisible(false);
  };

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    await fetchItems(undefined, values.keywords);
  }

  const columns = [
    {
      // title: "ID",
      title: <FormattedMessage id="ClientGroupTableHeaderId" />,
      dataIndex: "id",
      key: "id",
      sorter: true,
    },
    {
      // title: "Group name",
      title: <FormattedMessage id="ClientGroupTableHeaderGroupName" />,
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text: any) => {
        return <>{text}</>;
      },
    },
    {
      // title: "Client Name",
      title: <FormattedMessage id="ClientGroupTableHeaderClientName" />,
      width: "10%",
      dataIndex: "clientName",
      key: "clientName",
      sorter: true,
    },
    {
      // title: "Time added",
      title: <FormattedMessage id="ClientGroupTableHeaderTimeAdded" />,
      width: "20%",
      dataIndex: "created",
      key: "created",
      sorter: true,
      defaultSortOrder,
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
    {
      // title: "Time Modified",
      title: <FormattedMessage id="ClientGroupTableHeaderTimeModified" />,
      width: "20%",
      dataIndex: "lastModified",
      key: "lastModified",
      sorter: true,
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
    {
      // title: "Action",
      title: <FormattedMessage id="ClientGroupTableHeaderAction" />,
      width: "20%",
      render: (text: any, record: ClientGroupDto) => (
        <Space>
          {/* <Popover
            content={<Qr url="https://google.com" />}
            title="Registration QR code"
            trigger="click"
          >
            <Button>Generate QR</Button>
          </Popover> */}
          <Button
            type="primary"
            onClick={() => {
              navigate("/admin/clientgroup/" + record.id + "/groupsetting");
            }}
          >
            <Space>
              <FormattedMessage id="StaticDetailsButton" />
            </Space>
          </Button>
          {auth.can(ACTION.Create, "ClientSubGroups") ? (
            <Button
              type="primary"
              onClick={() => OpenModalAddSubClientGroup(record)}
            >
              <Space>
                <PlusOutlined />
                <FormattedMessage id="ClientGroupClientGroupTableAddSubButton" />
              </Space>
            </Button>
          ) : null}
          {auth.can(ACTION.Archive, "Archive") ? (
            <Button
              type="primary"
              onClick={() => handleArchiveClientGroup(record.id as number)}
            >
              <Space>
                <FormattedMessage id="ClientGroupClientGroupTableArchiveButton" />
              </Space>
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];

  const onTableRowExpand = (expanded: boolean, record: any) => {
    const keys = [];
    if (expanded) {
      keys.push(record.id);
    }

    setExpandedRowKeys(keys);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Layout title="ClientGroupClientGroupNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <ClientGroupMenu />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={handleKeywordsSearch}
              >
                <Form.Item name="keywords">
                  <Input placeholder={StaticKeywords} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Space>
            <Space wrap>
              {auth.can(ACTION.Create, "ClientGroups") ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateModalVisible(true);
                  }}
                >
                  <Space>
                    <PlusOutlined />
                    <FormattedMessage id="ClientGroupClientGroupContentAddButton" />
                  </Space>
                </Button>
              ) : null}
              {auth.can(ACTION.Download, "ClientGroupAssessmentTemplates") ? (
                <Button type="primary" onClick={handleDownloadTemplates}>
                  <FormattedMessage id="ClientGroupClientGroupContentDownloadTemplateButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "ClientGroupAssessmentTemplates") ? (
                <Upload action={handleUploadTemplates}>
                  <Button type="primary">
                    <FormattedMessage id="ClientGroupClientGroupContentUploadTemplateButton" />
                  </Button>
                </Upload>
              ) : null}
              {auth.can(ACTION.Download, "ClientGroups") ? (
                <Button type="primary" onClick={handleDownloadClientGroups}>
                  <FormattedMessage id="ClientGroupClientGroupContentDownloadButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "ClientGroups") ? (
                <Upload action={handleUploadClientGroups}>
                  <Button type="primary">
                    <FormattedMessage id="ClientGroupClientGroupContentUploadButton" />
                  </Button>
                </Upload>
              ) : null}
              {auth.can(
                ACTION.Download,
                "ClientGroupReportGenerationSettings"
              ) ? (
                <Button
                  type="primary"
                  onClick={handleDownloadReportGenerationSetting}
                >
                  <FormattedMessage id="ClientGroupClientGroupContentDownloadSettingButton" />
                </Button>
              ) : null}
              {auth.can(
                ACTION.Upload,
                "ClientGroupReportGenerationSettings"
              ) ? (
                <Upload action={handleUploadReportGenerationSetting}>
                  <Button type="primary">
                    <FormattedMessage id="ClientGroupClientGroupContentUploadSettingButton" />
                  </Button>
                </Upload>
              ) : null}
            </Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
            expandable={{
              expandedRowKeys: expandedRowKeys,
              onExpand: onTableRowExpand,
              expandedRowRender: (record, index, indent, expanded) => (
                <TreeTable
                  id={record.id}
                  columns={columns}
                  expanded={expanded}
                />
              ),
              rowExpandable: (record) => record.hasSubGroup as boolean,
            }}
          />
        </Space>
      </Card>
      <ClientGroupCreateModal
        visible={createModalVisible}
        handleAction={handleAddClientGroup}
        handleCancelAction={handleCancelAddClientGroup}
        createTopMode={true}
        titleId="ClientGroupClientGroupModalAddTitle"
      />
      <ClientGroupCreateModal
        visible={createSubGroupModalVisible}
        handleAction={handleAddSubClientGroup}
        handleCancelAction={handleCancelAddSubClientGroup}
        clientGroup={createSubGroupModalGroup}
        titleId="ClientGroupClientGroupModalAddSubTitle"
      />
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
