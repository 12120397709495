import { ClientGroupDto, ClientGroupsClient } from "@api";
import { TablePaginationConfig } from "antd";
import { Table } from "lib";
import React, { useEffect, useState } from "react";

type HeaderProps = {
  id: number | undefined;
  columns: any;
  expanded: boolean;
};

const defaultOrderBy = "name";
const defaultSortOrder = "ascend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["bottomCenter"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const TreeTable = ({ id, columns, expanded }: HeaderProps): JSX.Element => {
  const clientGroupsClient = new ClientGroupsClient();

  const [state, setState] = useState({
    items: [] as ClientGroupDto[],
    pagination: defaultPagination,
    loading: false,
  });

  const [expandedRowKeys, setExpandedRowKeys] = useState(
    undefined as React.Key[] | undefined
  );

  async function fetchItems(params: any = { pagination: state.pagination }) {
    setState({
      ...state,
      loading: true,
    });
    const res = await clientGroupsClient.getSubClientGroupsWithPagination(
      undefined,
      id,
      params.pagination.current,
      params.pagination.pageSize,
      capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
      (params.sortOrder ?? defaultSortOrder) == "ascend"
    );

    setState({
      ...state,
      items: (res.items as ClientGroupDto[]) || ([] as ClientGroupDto[]),
      pagination: {
        ...params.pagination,
        total: res.totalCount,
      },
      loading: false,
    });
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  const onTableRowExpand = (expanded: boolean, record: any) => {
    const keys = [];
    if (expanded) {
      keys.push(record.id);
    }

    setExpandedRowKeys(keys);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    if (expanded) fetchItems();
    else setExpandedRowKeys([]); // collapse all expended
  }, [expanded]);

  return (
    <Table
      dataSource={[...state.items]}
      columns={columns}
      rowKey={(r) => r.id}
      pagination={state.pagination}
      loading={state.loading}
      onChange={handleTableChange}
      expandable={{
        expandedRowKeys: expandedRowKeys,
        onExpand: onTableRowExpand,
        expandedRowRender: (record, index, indent, expanded) => {
          return (
            <TreeTable id={record.id} columns={columns} expanded={expanded} />
          );
        },
        rowExpandable: (record) => record.hasSubGroup as boolean,
      }}
    />
  );
};

export default TreeTable;
