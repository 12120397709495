import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const ClientGroupMenu: React.FC = () => (
  <MenuTabs
    items={[
      {
        id: "ClientGroupClientGroupNavigationTitle",
        path: "/admin/clientgroup",
        page: "ClientGroups",
        subpage: "ClientGroups",
      },
    ]}
  />
);

export default ClientGroupMenu;
